import React, { useEffect, useState } from 'react';
import './App.css';
import backgroundImage from './assets/background.jpg';
// Import food images
import pizza from './assets/food/pizza.webp';
import sausages from './assets/food/sausages.webp';
import iceCream from './assets/food/iceCream.webp';
import spaghetti from './assets/food/spaghetti.webp';
import lasagna from './assets/food/lasagne.webp';
import watermelon from './assets/food/watermelon.webp';
import rockMelon from './assets/food/rockmelon.webp';
import apples from './assets/food/apples.webp';
import peaches from './assets/food/peaches.webp';
import pears from './assets/food/pears.webp';
import bananas from './assets/food/bananas.webp';
import beef from './assets/food/meat.webp';
import cakes from './assets/food/cakes.webp';
import cupcakes from './assets/food/cupcakes.webp';
import chocolate from './assets/food/chocolate.webp';
import lollies from './assets/food/lollies.webp';
import brownies from './assets/food/brownies.webp';

const App = () => {
  const [showWelcome, setShowWelcome] = useState(false);
  const [animateFood, setAnimateFood] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  
  // Array of food images
  const foodImages = [
    pizza, sausages, iceCream, spaghetti, lasagna, watermelon, rockMelon, apples,
    peaches, pears, bananas, beef, cakes, cupcakes, chocolate, lollies, brownies
  ];

  useEffect(() => {
    setTimeout(() => {
      setShowWelcome(true);
    }, 2000);
  }, []);

  const handleGoClick = () => {
    setAnimateFood(true);
    setTimeout(() => {
      setAnimateFood(false); // Reset animation state
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % foodImages.length); // Move to the next image
    }, 4000); // Match this duration to your CSS animation duration
  };

  return (
    <div className="app" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {showWelcome && <div className="welcome-message">Welcome to Otto's website</div>}
      <button className="go-button" onClick={handleGoClick}>GO</button>
      <div className="visitor-counter">Visitor Count: 0</div>
      <div className="food-image-container">
        {foodImages.map((src, index) => (
          <img key={index} src={src} alt="Food" className={`food-image ${animateFood && index === currentImageIndex ? 'animate' : ''}`} />
        ))}
      </div>
    </div>
  );
};

export default App;